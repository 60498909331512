<template>
  <div
    :class="[
      'absolute text-blue-haze left-0 w-full rounded-6 px-4 py-3 flex items-center password-notification-bg transition-all ease-in-out duration-200',
      isVisible ? 'opacity-100' : 'opacity-0',
    ]"
    :style="isVisible ? `bottom: ${64 * index}px` : 'bottom: 0px'"
  >
    <img
      v-if="!isError"
      src="@/assets/icons/success-sent.png"
      class="w-8 h-8"
    />
    <img v-else src="@/assets/icons/error-sent.png" class="w-8 h-8" />

    <p
      class="px-5 text-sm w-available"
      :class="{
        'text-accent-green': !isError,
        'text-red': isError,
      }"
    >
      {{ message }}
    </p>

    <button
      id="close-button"
      class="cursor-pointer w-6 h-6 flex items-center justify-center ml-auto"
      @click="closeNotification"
    >
      <img src="@/assets/icons/close.png" class="w-3 h-3" />
    </button>
  </div>
</template>

<script setup>
const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false,
  },
  message: {
    type: String,
    default: "",
  },
  isError: {
    type: Boolean,
    default: false,
  },
  index: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(["close"]);
const closeNotification = () => emit("close");
</script>
